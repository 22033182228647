import React from "react";
import {Link } from "gatsby";
import "./_services.scss";
import Image from "../base/image";

const Services = () => (
    <div className="services-component">
        <div className="services-item">
            <span className="services-item-inner">
                <h4>Our Products</h4>
                <div className="btn btn-white buy-now">Buy Now</div>
            </span>
        </div>
        <div className="services-item">
            <Image filename="photo.png" alt="Product Photography" />
            <Link to="/services/products" className="services-item-inner">
               <h4>Product Photography</h4>
               <div className="btn btn-white buy-now">Buy Now</div>
            </Link>
        </div>
        <div className="services-item">
            <Image filename="studio.png" alt="Studio Rental" />
            <div className="services-item-inner">
                <h4>Studio Rental</h4>
                <Link to="/services/studio" className="btn btn-white buy-now">Buy Now</Link>
            </div>
        </div>
        <div className="services-item">
            <Image filename="websites.png" alt="Off-the Shelf Websites" />
            <Link to="/services/websites" className="services-item-inner">
                <h4>Off-the Shelf Websites</h4>
                <div className="btn btn-white buy-now">Buy Now</div>
            </Link>
        </div>
        <div className="services-item">
            <Image filename="content.png" alt="Content and PR Strategy" />
            <Link to="/services/content" className="services-item-inner">
                <h4>Content and PR Strategy</h4>
                <div className="btn btn-white buy-now">Buy Now</div>
            </Link>
        </div>
        <div className="services-item">
            <Image filename="cmo.png" alt="Hire a CMO" />
            <Link to="/services/cmo" className="services-item-inner">
                <h4>Hire<br/> a CMO</h4>
                <div className="btn btn-white buy-now">Buy Now</div>
            </Link>
        </div>
        <div className="services-item double-width">
            <Image filename="advertising.png" alt="Digital Advertising" />
            <Link to="/services/advertising" className="services-item-inner">
                <h4>Digital Advertising</h4>
                <div className="btn btn-white buy-now">Buy Now</div>
            </Link>
        </div>
        <div className="services-item">
            <Image filename="support.png" alt="Webmaster + Marketing IT Support" />
            <Link to="/services/support" className="services-item-inner">
                <h4>Webmaster<br/> + Marketing<br/> IT Support</h4>
                <div className="btn btn-white buy-now">Buy Now</div>
            </Link>
        </div>
    </div>
);

export default Services;
