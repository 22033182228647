import React from "react";
import Layout from "../components/base/layout";
import SEO from "../components/base/seo";

import Accordion from "../components/accordion/accordion";
import Services from "../components/services/services";
import "../styles/pages/_home.scss";
import figure from "../images/home/figure.png";

const IndexPage = () => (
    <Layout type="template-home home-index">
        <SEO title="Home"/>
        <section className="section-intro">
            <div className="intro-top">
                <div className="container">
                    <h2>Problems?</h2>
                    <span className="intro-desc">
                        <p>Want more from your marketing but don't want to deal the regular agency rigamarole?</p>
                    </span>
                </div>
            </div>
            <div className="intro-bottom">
                <div className="container">
                    <h2>Solved.</h2>
                    <span className="intro-desc">
                        <p>We do all the things you need us to do and we know what we’re doing.</p>
                    </span>
                </div>
            </div>
        </section>
        <section className="section-what-we-do" id='section-what-we-do'>
            <h3 className="what-we-do-desc">We just want to do fun work, simple work,<br/>
            well done work – but without the complicated<br/>
            agreements, workflows or onerous processes.</h3>
            <h2><span className="text-red">What</span> are we offering right now?</h2>
            <div className="container">
                <Services />
            </div>
        </section>
        <section className="section-call-out">
            <div className="container">
                <div className="call-out-wrapper">
                    <h2>We are scientists in a lab, not a manufacturing line.</h2>
                    <h3>We come into the lab to solve challenging problems through experimentation, continual learning, and
                        breakthroughs. We apply what we think we know, unlearn what we thought we
                        knew, and teach ourselves to let go of our preconceived notions of what our work is supposed to look like.
                    </h3>
                </div>
            </div>
        </section>
        <section className="section-faq">
            <div className="container">
                <h2>FAQ</h2>
                <Accordion loadmore={true}>
                    <div label='Why should we hire you?'>
                        <p>We know what we are doing and our team has worked on brands/businesses that are pretty big and also brands/businesses that are still ideas on napkins. We have super talented people in each discipline and we properly manage your project using our big agency tools and processes.</p>
                    </div>
                    <div label='What industries does HSMG Lab work in?'>
                        <p>Almost anything that is legal and ethical. We generally avoid politicians and political campaigns as most of those people are vapid and fake and have nothing real to say and are not trying to accomplish real change.</p>
                    </div>
                    <div label='What size of companies does HSMG Lab work with?'>
                        <p>Any company that can afford us. You could be a solo-prenuer trying to build yourself the dream job, not a full company – and that is perfect. OR you could have a team of 25 and are looking to double every year for the next 5 years – and we would be excited to strap ourselves to that rocketship.</p>
                    </div>
                    <div label='Do you work with clients in other countries?'>
                        <p>Yep! We work with any English speaking client/brand that is looking to actively market in an English speaking country and Western culture. Other markets and cultures will take some specialized knowledge and resources that don’t fit our simple pricing model and scaling services.</p>
                    </div>
                    <div label='How much does it cost to use HSMG Lab’s services?'>
                        <p>All of our prices are listed for instant purchase and for price shopping your cousin Greg who says they know everything about Google.</p>
                    </div>
                    <div label='What should my marketing budget be?'>
                        <p>30% of revenue if you want to grow. 10% if you want to stay steady. Anywhere between if you want to test out new markets, ideas or products.</p>
                    </div>

                    <div label='How is HSMG Lab able to provide fixed price services?' hidden="true">
                        <p>Because we don’t care if we are profitable. Our parent agency can deal with the finances, we just want to work on the cool projects that we want without any of the headache or judgement that comes from a finance department.</p>
                    </div>
                    <div label='How does your invoicing process work?' hidden="true">
                        <p>You pay before we start working. It is that simple. You can try and charm us into how lucky we would be to work with you or gain experience, blah, blah, blah – but at the end of the day just pay us and let us get to work. Do you really think we could have grown for the past 10 years if we were screwing people over???</p>
                    </div>
                    <div label="Will I have to sign any ongoing contracts?" hidden="true">
                        <p>Maybe. If your business/brand grows – our simple approach may no longer make sense and you may need to bring on more sophisticated and time intensive approaches to your growth marketing. Nothing lasts forever.</p>
                    </div>
                    <div label="Do I have to commit to a minimum budget?" hidden="true">
                        <p>Nope. You have already paid us before we start, so we are going to spend all of that money to get you where you need to go.</p>
                    </div>
                    <div label="What if I want to cancel services?" hidden="true">
                        <p>Just stop working with us. You have already paid, so if you don’t want to continue just let us know where to send files or to hand things over to.</p>
                    </div>
                    <div label="How quickly can we get started?" hidden="true">
                        <p>As soon as your cheque (sorry no cheques or bitcoin accepted) clears, we get rolling and you will start seeing alerts and invites into our project management platform.</p>
                    </div>
                </Accordion>
            </div>
        </section>
        <section className='section-cta'>
            <div className="container">
                <div className="cta-wrapper">
                    <img src={figure} alt=""/>
                    <div className=''>
                        <h4>Ready to finally  take the leap?</h4>
                        <a href="#section-what-we-do" className='btn btn-blue'>Do it</a>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
);

export default IndexPage
